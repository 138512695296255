
































import { Action, Getter, State } from 'vuex-class'
import { LocatorTransitions } from '@/animations'
import { storeLocatorService } from '@/services'
import { Component } from 'vue-property-decorator'
import { StoreLocator as Locator } from '@/constants'
import { ProductState } from '@/store/modules/products/types'
import { RouterState } from '@/store/modules/router/types'
import Loop from '@/components/atoms/Loop.vue'

@Component({
  components: {
    Loop,
  },
})
export default class StoreLocator extends LocatorTransitions {
  @Getter('products/current') current!: ProductState
  @Getter('locator/search') locatorSearch!: string
  @Action('locator/setSearch') setLocatoSearch!: (value: string) => void
  @State('router') router!: RouterState

  fetched = false
  loaded = false
  source = null

  iframeLoaded() {
    this.loaded = true
  }

  async created() {
    //this.$state.needsScroll = false
    await this.$timer.defer(1.2)
    const { to, from } = this.router
    const data = (await storeLocatorService()) as any
    const search = to.params.product && !from.name ? 'product' : this.locatorSearch
    const query =
      this.current && this.current.locator && search === 'product'
        ? `?product=${this.current.locator}`
        : ''
    this.source = `${data.source}${query}` as any
    console.log({ search }, this.source)
    this.setLocatoSearch('all')
    this.fetched = true
  }

  onIframeMessage(event: any) {
    if (Locator.CLOSE_EVENT === event.data) {
      this.$router.push({ hash: '' })
      console.log(`iframe onMessage: ${event.data}`)
    }
  }

  mounted() {
    window.addEventListener('message', this.onIframeMessage)
  }

  beforeDestroy() {
    window.removeEventListener('message', this.onIframeMessage)
  }
}
