import Vue from 'vue'

export default Vue.observable({
  smoothScroll: false,
  scrollOffset: false,
  needsScroll: true,
  scrollSnap: false,
  transition: false,
  needsLogo: true,
  fetching: false,
  locale: false,
})
