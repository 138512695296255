

















import { Component, Vue } from 'vue-property-decorator'
import Resizer from '@/observable/Resizer'

@Component({
  components: {
    Resizer,
  },
})
export default class Columns extends Vue {
  get cols() {
    return this.$device.mobile ? 4 : 4
  }
}
