




































































import { Action, Getter, State } from 'vuex-class'
import { MenuTransitions } from '@/animations'
import { Component } from 'vue-property-decorator'
import { RouterState } from '@/store/modules/router/types'
import { RecipeState } from '@/store/modules/recipes/types'
import { ProductState } from '@/store/modules/products/types'
import LangSwitch from '@/components/atoms/LangSwitch.vue'
import Scroller from '@/components/organsims/Scroller.vue'
import SolidBtn from '@/components/atoms/btns/Solid.vue'
import Links from '@/components/molecules/Links.vue'
import SmoothScrollbar from 'smooth-scrollbar'
import i18n from '@/i18n'

type SharedScrollbar = SmoothScrollbar | HTMLElement | null

@Component({
  components: {
    LangSwitch,
    SolidBtn,
    Scroller,
    Links,
  },
})
export default class Menu extends MenuTransitions {
  @Action('app/openMenu') openMenu!: () => void
  @Action('locator/setSearch') setLocatorSearch!: (search: string) => void
  @Getter('recipes/current') currentRecipe!: RecipeState
  @Getter('products/current') currentProduct!: ProductState
  @State('router') router!: RouterState

  scroller!: SharedScrollbar

  get nav() {
    return this.$t('nav')
  }

  get path() {
    return `/${i18n.locale}`
  }

  /* get locale() {
    return 'it' === i18n.locale ? 'en' : 'it'
  }

  get language() {
    return 'it' === i18n.locale ? 'ENG' : 'ITA'
  }

  async changeLang() {
    const { name, meta } = this.router.to
    const prefix = `${process.env.VUE_APP_PUBLIC_PATH}${this.locale}`
    const slugName = name.replace('-leaf', '')
    const slug =
      'product' === slugName
        ? this.currentProduct.routes[this.locale]
        : 'recipe' === slugName
        ? this.currentRecipe.routes[this.locale]
        : ''

    const path = meta.abstract
      ? `${prefix}/${meta.slugs[this.locale].replace(`:${slugName}`, slug)}`
      : `${prefix}/${meta.slugs[this.locale]}`

    await this.$router.push({ path })

    location.reload()
  } */

  /* async selectLang(locale: string) {
    const { name, meta } = this.router.to
    const prefix = `${process.env.VUE_APP_PUBLIC_PATH}${locale}`
    const slugName = name.replace('-leaf', '')
    const slug =
      'product' === slugName
        ? this.currentProduct.routes[locale]
        : 'recipe' === slugName
        ? this.currentRecipe.routes[locale]
        : ''

    const path = meta.abstract
      ? `${prefix}/${meta.slugs[locale].replace(`:${slugName}`, slug)}`
      : `${prefix}/${meta.slugs[locale]}`

    await this.$router.push({ path })

    location.reload()
  } */

  async selectLang(locale: string) {
    const prefix = `${process.env.VUE_APP_PUBLIC_PATH}${locale}`
    const path = `${prefix}/`

    await this.$router.push({ path })

    location.reload()
  }

  async openStoreLocator(event: MouseEvent) {
    await this.setLocatorSearch('all')
    const target = event.currentTarget as HTMLLinkElement
    const hash = `#${target.href.split('#')[1]}`
    this.$router.push({ hash })
  }

  mounted() {
    this.scroller = !this.$device.mobile
      ? SmoothScrollbar.init(this.$refs.scroller, {})
      : this.$refs.scroller
  }

  beforeDestroy() {
    if (this.scroller) {
      if (!(this.scroller instanceof HTMLElement)) this.scroller.destroy()
      this.scroller = null
    }
  }
}
