export default {
  scrollTo: () => ({}),
  scrollTop: () => ({}),
  scrollSnap: () => ({}),

  enhancedIncludes: (source: string, match: string | string[]) => {
    if (!Array.isArray(match)) return !!~source.indexOf(match)
    for (let i = match.length - 1; i >= 0; i--) if (~source.indexOf(match[i])) return true
    return false
  },
}
