import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Resizer extends Vue {
  viewport = { width: 0, height: 0 }

  resize() {
    this.viewport.width = window.innerWidth
    this.viewport.height = window.innerHeight
    this.$emit('resize', this.viewport)
  }

  mounted() {
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  }

  render() {
    return (
      !this.$scopedSlots.$hasNormal &&
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        viewport: this.viewport,
      })
    )
  }
}
