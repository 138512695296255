import Vue from 'vue'

class Keyboard extends Vue {
  bindKeyUp = this.onKeyUp.bind(this)

  constructor() {
    super()

    if (this.$device.mobile) return

    window.addEventListener('keyup', this.bindKeyUp, this.$passive)
  }

  onKeyUp({ code }: KeyboardEvent) {
    if ('ArrowUp' === code) this.$emit('prev')

    if ('ArrowDown' === code) this.$emit('next')
  }

  dispose() {
    window.removeEventListener('keyup', this.bindKeyUp, this.$passive)
  }
}

Vue.use(() => (Vue.prototype.$keyboard = new Keyboard()))

declare module 'vue/types/vue' {
  interface Vue {
    $keyboard: Keyboard
  }
}
