import { MutationTree } from 'vuex'
import { ProductsState } from './types'
import i18n from '@/i18n'

export const mutations: MutationTree<ProductsState> = {
  collection(state, { widgets, collection }) {
    state.collection = collection
    state.widgets = widgets
    //state.fetched = true
  },

  navigation(state, { label, routes: { [i18n.locale]: route } }) {
    state.navigation.push({
      index: state.navigation.length,
      label,
      route,
    })
  },

  update(state, payload) {
    const length = state.collection.length - 1
    state.prev = state.next
    state.next = payload < 0 ? 0 : payload > length ? length : payload
    //state.next = payload < 0 ? length : payload > length ? 0 : payload
  },

  fetched(state, payload) {
    state.fetched = payload
  },

  theme(state, payload) {
    state.theme = payload
  },

  reset(state) {
    state.prev = -1
    state.next = 0
  },
}
