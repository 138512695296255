import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar'

class ScrollSnapPlugin extends ScrollbarPlugin {
  static pluginName = 'snap'

  static defaultOptions = {
    enable: false,
    offset: 0,
  }

  transformDelta(delta: any) {
    const { enable, offset } = this.options
    return enable ? { x: 0, y: offset } : delta
  }
}

Scrollbar.use(ScrollSnapPlugin)

//() => scrollbar.updatePluginOptions('snap', { enable: true })
//() => scrollbar.updatePluginOptions('snap', { enable: false })
//() => scrollbar.updatePluginOptions('snap', { offset: 0 })
