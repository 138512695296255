/* eslint-disable */
const map = new Map()

export const addAsset = (key: string, value: any) => {
  map.set(key, value)
}

export const getAsset = (key: string) => {
  return map.get(key)
}

export const getAll = (callback: (value: any, key: any, map: Map<any, any>) => void) => {
  map.forEach(callback)
}
