import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { RootState } from '../../types'
import { SceneState } from './types'

export const state: SceneState = {
  background: {
    uStrokeFrequency: 28,
    uStrokeBleeding: 0.05,
    uStrokeRotation: 0,
    uStrokeStagger: 0.3,
    uStrokeLength: 0.5,
    uStrokeWidth: 0.018,
    uTransition: 0,
    uProgress: 1,
  },

  packages: {
    uStrokeFrequency: 24.3,
    uStrokeBleeding: 0.05,
    uStrokeRotation: 0,
    uStrokeStagger: 0.3,
    uStrokeLength: 1,
    uStrokeWidth: 0.025,
    uProgress: 1,
    uPosition: 0,
    uRotation: 0,
  },

  controls: {
    orbit: false,
  },
}

const namespaced = true

export const scene: Module<SceneState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state,
}
