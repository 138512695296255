import { MutationTree } from 'vuex'
import { RecipesState } from './types'

export const mutations: MutationTree<RecipesState> = {
  widgets(state, { widgets }) {
    state.widgets = widgets
    state.fetched = true
  },

  current(state, { model }) {
    state.current = model
  },
}
