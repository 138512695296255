/* eslint-disable */
import { addAsset } from './cache'
import { LoadingManager, TextureLoader } from 'three'

export const fetchBmpFonts = () => {
  const manager = new LoadingManager()

  const font = require('@/assets/msdf/Rajdhani-SemiBold.json')
  const msdf = require('@/assets/msdf/Rajdhani-SemiBold.png')

  const textureLoader = new TextureLoader(manager)
  textureLoader.load(msdf, (texture) => addAsset('msdf', texture))

  addAsset('font', font)

  return new Promise<void>((resolve) => {
    manager.onLoad = () => resolve()
  })
}
