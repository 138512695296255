import Vue from 'vue'
import polyfills from './modules/polyfills'
import * as device from './modules/device'
import state from './modules/state'
import timer from './modules/timer'
import css from './modules/css'
import fn from './modules/fn'

Vue.use(() => (Vue.prototype.$passive = polyfills.passive))
Vue.use(() => (Vue.prototype.$device = device))
Vue.use(() => (Vue.prototype.$state = state))
Vue.use(() => (Vue.prototype.$timer = timer))
Vue.use(() => (Vue.prototype.$css = css))
Vue.use(() => (Vue.prototype.$fn = fn))
