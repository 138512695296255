import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { ProductsState } from './types'
import i18n from '@/i18n'

export const getters: GetterTree<ProductsState, RootState> = {
  prev: ({ prev }) => prev,
  next: ({ next }) => next,

  theme: ({ theme }) => theme,

  fetched: ({ fetched }) => fetched,

  widgets: ({ widgets }) => widgets,
  collection: ({ collection }) => collection,
  navigation: ({ navigation }) => navigation,
  category: (_, { current }) => current && current.category,
  current: ({ collection, next }) => collection[next] || collection[0],

  /* navigation: ({ collection }) =>
    collection.map(({ label, routes: { [i18n.locale]: route } }, index) => ({
      label,
      route,
      index,
    })), */

  /* navigation: ({ collection }) => Object.values(
    collection.reduce((store, { route, label, category }, index) => {
      store[category.name] = store[category.name] || { ...category, modules: [] }
      store[category.name].modules.push({ label, route, index })
      return store
    }, {} as any) ), */

  categoryIndex: (_, { navigation, category }) =>
    navigation.indexOf(navigation.find(({ name }: any) => name === category.name)),

  direction: ({ next, prev }) => (next > prev ? 1 : -1), // prev === 0 && next === length - 1 ? -1 : prev === length - 1 && next === 0 ? 1 : next > prev ? 1 : -1,
  find:
    ({ collection }) =>
    (route: string) =>
      route
        ? collection.indexOf(
            collection.find(({ routes }: any) => routes[i18n.locale] === route) as any
          )
        : 0,
}
