import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { RootState } from '@/store/types'
import { AppState } from './types'

export const state: AppState = {
  menuopen: false,
  cookies: false,
  loaded: false,
}

const namespaced = true

export const app: Module<AppState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state,
}
