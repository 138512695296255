import WebFont from 'webfontloader'

export const fetchWebFonts = () => {
  return new Promise<void>((resolve) => {
    WebFont.load({
      active: resolve,
      classes: false,
      typekit: {
        id: 'vmp5lay',
      },
    })
  })
}
