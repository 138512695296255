











import { gsap } from 'gsap'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Cookies extends Vue {
  enter(el: HTMLElement, done: () => void) {
    gsap.fromTo(
      el,
      { y: 100 },
      { y: 0, duration: 2, delay: 2, ease: 'expo.inOut', onComplete: done }
    )
  }

  leave(el: HTMLElement, done: () => void) {
    gsap.to(el, { y: 100, duration: 1, ease: 'expo.out', onComplete: done })
  }
}
