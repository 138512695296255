import Vue from 'vue'
import axios from 'axios'
import VueI18n from 'vue-i18n'
import { localeService } from '@/services'

Vue.use(VueI18n)

const ref = new Vue()

const { VUE_APP_HTML_LOCALE, VUE_APP_I18N_LOCALE, VUE_APP_I18N_FALLBACK_LOCALE } = process.env

const i18n = new VueI18n({
  locale: VUE_APP_HTML_LOCALE || VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: VUE_APP_I18N_FALLBACK_LOCALE || 'en',
})

const loadedLanguages: string[] = []

const setI18nLanguage = (lang: string): string => {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html')?.setAttribute('lang', lang)
  return lang
}

export const fetchI18nLocale = async (lang: string): Promise<string> => {
  if (loadedLanguages.includes(lang)) return Promise.resolve(setI18nLanguage(lang))

  const messages = (await localeService(lang)) as any
  const filteredMessages = {
    ...messages,
    nav: {
      main: messages.nav.main.filter((route: any) => !route.abstract),
      subs: messages.nav.subs.filter((route: any) => !route.abstract),
    },
  }

  i18n.setLocaleMessage(lang, filteredMessages)
  loadedLanguages.push(lang)
  ref.$state.locale = true

  Promise.resolve(setI18nLanguage(lang))

  return messages
}

export default i18n
