






















import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { LogoTransitions, IconsTransitions } from '@/animations'
import Cookies from '@/components/molecules/Cookies.vue'
import Swipe from '@/components/atoms/icons/Swipe.vue'
import Mouse from '@/components/atoms/icons/Mouse.vue'
import Logo from '@/components/atoms/icons/Logo.vue'
import Hamb from '@/components/atoms/icons/Hamb.vue'
import Menu from '@/components/molecules/Menu.vue'
import i18n from '@/i18n'

@Component({
  components: {
    Cookies,
    Swipe,
    Mouse,
    Logo,
    Menu,
    Hamb,
  },
})
export default class Header extends mixins(LogoTransitions, IconsTransitions) {
  @Getter('app/menuopen') menuopen!: boolean
  @Action('app/openMenu') openMenu!: () => void
  @Getter('app/cookies') needsCookies!: boolean
  @Action('app/acceptCookies') acceptCookies!: () => void

  get homepage() {
    return `/${i18n.locale}`
  }

  get icon() {
    return this.$device.mobile ? 'Swipe' : 'Mouse'
  }
}
