export const MediaQ = {
  XS: 360,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
}

export const StoreLocator = {
  HASH: '#store-locator',
  CLOSE_EVENT: 'CLOSEDIRECTORY',
}

export const Cookie = {
  NAME: process.env.VUE_APP_COOKIE_NAME as string,
}
