import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { fetchBmpFonts, fetchMetaTags, fetchNextPackAssets, productsService } from '@/services'
import { ProductsState } from './types'
import { getRouter } from '@/router'
import i18n from '@/i18n'
//import Vue from 'vue'

export const actions: ActionTree<ProductsState, RootState> = {
  async meta(_, { page }) {
    const { params } = getRouter().currentRoute
    await fetchMetaTags(page, params)
  },

  async fetch({ commit, state }) {
    if (!state.fetched) {
      await fetchBmpFonts()
      const widgets = (await productsService()) as any
      const { collection } = widgets.find((widget: any) => 'webgl' === widget.theme.type)
      commit('collection', { widgets, collection })
      //const { params } = getRouter().currentRoute
      //await fetchAssets(collection, params)
    }
  },

  async fetchNextPack({ dispatch, state }) {
    const {
      params: { product },
    } = getRouter().currentRoute

    const leaf = state.collection.find(
      ({ routes, loaded, loading }) => routes[i18n.locale] === product && !(loaded || loading)
    )

    const pack = leaf || state.collection.find(({ loaded, loading }) => !loaded && !loading)

    if (pack) {
      pack.loading = true

      await fetchNextPackAssets(pack)

      pack.loading = false
      pack.loaded = true

      //console.log(pack.routes[i18n.locale])

      await dispatch('addNavigationItem', pack)
      await dispatch('setFetched', true)

      setTimeout(() => {
        dispatch('fetchNextPack')
      }, 300)

      /* Vue.nextTick().then(() => {
        dispatch('fetchNextPack')
      }) */
    }
  },

  addNavigationItem({ commit }, payload) {
    commit('navigation', payload)
  },

  setFetched({ commit }, payload) {
    commit('fetched', payload)
  },

  setTheme({ commit }, payload) {
    commit('theme', payload)
  },

  update({ commit }, payload) {
    commit('update', payload)
  },

  reset({ commit }) {
    commit('reset')
  },
}
