import { gsap } from 'gsap'
import { State } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'
import { leaveProducts, leaveProductLeaf } from '@/animations/Products'

@Component
export class RoutesTransitions extends Vue {
  @State('router') router!: any

  $refs!: {
    columns: Vue
  }

  get needsColumns() {
    const { to, from } = this.router
    return from ? to.meta.name !== from.meta.name : true
  }

  enter(el: HTMLElement, done: () => void) {
    done
  }

  reveal() {
    const { columns } = this.$refs
    const { boxes } = columns.$refs
    const shuffle = gsap.utils.shuffle(boxes as [])
    gsap
      .timeline({ delay: 0.4, defaults: { transformOrigin: 'left top' } })
      .fromTo(
        shuffle,
        { scaleY: 1 },
        { duration: 1, scaleY: 0, stagger: { amount: 0.4 }, ease: 'expo.out' },
        '<'
      )
  }

  leave(el: HTMLElement, done: () => void) {
    if (this.needsColumns) {
      const { columns } = this.$refs
      const { boxes } = columns.$refs
      const shuffle = gsap.utils.shuffle(boxes as [])
      const duration = this.router.from.name ? 0.8 : 0
      /* const timeline =  */ gsap
        .timeline({ onComplete: done, defaults: { transformOrigin: 'left bottom' } })
        .fromTo(
          shuffle,
          { scaleY: 0 },
          { duration, scaleY: 1, stagger: { amount: 0.4 }, ease: 'power2.in' },
          '<'
        )
      //console.log(timeline.totalDuration())
    } else {
      if ('products' === el.id) leaveProducts(el, done)
      if ('product-leaf' === el.id) leaveProductLeaf(el, done)
    }
  }
}
