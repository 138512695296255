import { PerspectiveCamera } from 'three'
import { MediaQ } from '@/constants'

export class Camera {
  webgl: PerspectiveCamera

  constructor() {
    this.webgl = new PerspectiveCamera(45, 1, 0.01, 100)
    this.webgl.up.set(0, 1, 0)
  }

  setSize(width: number, height: number) {
    const aspect = width / height

    const z = MediaQ.MD > width ? 5 : 4
    this.webgl.position.set(0, 0, z)

    this.webgl.aspect = aspect
    this.webgl.updateProjectionMatrix()
  }
}
