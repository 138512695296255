import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { RootState } from '@/store/types'
import { ProductsState } from './types'

export const state: ProductsState = {
  fetched: false,
  theme: undefined,
  collection: [],
  navigation: [],
  widgets: [],
  prev: -1,
  next: 0,
}

const namespaced = true

export const products: Module<ProductsState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state,
}
