






import { gsap } from 'gsap'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class SwipeIcon extends Vue {
  $refs!: {
    line: HTMLElement
  }

  mounted() {
    gsap
      .timeline({
        defaults: { duration: 1.5, repeatDelay: 0.25, repeat: -1, ease: 'power2.inOut' },
      })
      .fromTo(
        this.$refs.line,
        { yPercent: 100 },
        {
          motionPath: {
            path: [{ yPercent: 0 }, { yPercent: -100 }],
            curviness: 0,
            autoRotate: false,
          },
        }
      )
  }
}
