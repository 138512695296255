






import { gsap } from 'gsap'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class MouseIcon extends Vue {
  $refs!: {
    dot: HTMLElement
  }

  mounted() {
    gsap
      .timeline({ repeat: -1, defaults: { duration: 0.75, ease: 'power2.out' } })
      .add(gsap.fromTo(this.$refs.dot, { opacity: 0 }, { opacity: 1 }), '<')
      .add(
        gsap
          .timeline()
          .to(this.$refs.dot, { y: 7 }, '<')
          .to(this.$refs.dot, { opacity: 0 }, '<+.25'),
        '<+1'
      )
  }
}
