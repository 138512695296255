

















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Loop extends Vue {
  @Prop({ default: 'light' }) theme!: string
  @Prop({ default: 300 }) radius!: number
  @Prop({ default: 0.5 }) width!: number

  get size() {
    return this.radius - this.width
  }

  get style() {
    return {
      width: `${this.radius}px`,
      height: `${this.radius}px`,
    }
  }

  get viewBox() {
    const x = this.radius / 2
    const y = this.radius / 2
    const r = this.radius
    return `${x} ${y} ${r} ${r}`
  }

  mounted() {
    //console.log((this.$refs.circle as any).getTotalLength())
  }
}
