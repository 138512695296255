import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer'
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer'
import { WebGLRenderer } from 'three'
import { Camera, Scene } from './'
import { css } from '@/webgl/hooks/utils'

export class Renderer {
  webgl: WebGLRenderer
  css3d: CSS3DRenderer
  css2d: CSS2DRenderer

  constructor() {
    this.webgl = new WebGLRenderer({
      powerPreference: 'high-performance',
      antialias: true,
      stencil: false,
      alpha: false,
    })
    this.webgl.setClearColor(0x000000, 1)
    this.webgl.autoClear = false

    this.css3d = new CSS3DRenderer()
    this.css3d.domElement.id = 'css3d-renderer'

    this.css2d = new CSS2DRenderer()
    this.css2d.domElement.id = 'css2d-renderer'
  }

  get css(): CSS3DRenderer | CSS2DRenderer {
    return this[css()]
  }

  get domElement(): HTMLElement {
    return this.webgl.domElement
  }

  render(scene: Scene, camera: Camera) {
    this[css()].render(scene[css()], camera.webgl)
    this.webgl.render(scene.webgl, camera.webgl)
    //console.log(this.webgl.info.memory, this.webgl.info.render.calls)
  }

  setSize(width: number, height: number) {
    const dpr = Math.min(window.devicePixelRatio, 2)

    this.css2d.setSize(width, height)
    this.css3d.setSize(width, height)

    this.webgl.setSize(width, height)
    this.webgl.setPixelRatio(dpr)
  }
}
