import { Cookie } from '@/constants'
import { AppState } from './types'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import { fetchWebFonts, addTeadsPixel } from '@/services'
import Cookies from 'js-cookie'

export const actions: ActionTree<AppState, RootState> = {
  async fetch({ commit, dispatch, state }) {
    if (!state.loaded) {
      await fetchWebFonts()
      dispatch('getCookies')
      commit('loaded')
    }
  },

  openMenu({ commit, state }, payload) {
    if (state.menuopen !== payload) commit('menuOpen', payload)
  },

  getCookies({ commit }) {
    const cookies = Cookies.get(Cookie.NAME)
    commit('setCookies', cookies === undefined)
  },

  acceptCookies({ commit }) {
    Cookies.set(Cookie.NAME, '1')
    commit('setCookies', false)
    addTeadsPixel()
  },
}
