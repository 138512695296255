const map: any = {
  transition: 'transitionend',
  WebkitTransition: 'webkitTransitionEnd',
  MozTransition: 'mozTransitionEnd',
  OTransition: 'oTransitionEnd',
  msTransition: 'MSTransitionEnd',
}

const testEl = document.createElement('div')
const style = testEl.style
const endEvents = []

if (!('TransitionEvent' in window)) {
  delete map.transition
}

for (const styleName in map) {
  if (styleName in style) {
    endEvents.push(map[styleName])
    break
  }
}

const transitionend = endEvents[0]

export default {
  transitionend,
}
