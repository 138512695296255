/* eslint-disable */
const passiveSupport = (): boolean => {
  let support = false
  try {
    const opts = Object.defineProperty({}, 'passive', { get: () => (support = true) })
    window.addEventListener('test', () => {}, opts)
  } catch (e) {
    console.log(e)
  }
  return support
}

export default {
  passive: passiveSupport() ? { passive: false } : false,
}
