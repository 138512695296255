




























import { Component, Vue, Prop } from 'vue-property-decorator'
import Socials from '@/components/molecules/Socials.vue'

@Component({
  components: {
    Socials,
  },
})
export default class Links extends Vue {
  @Prop() socials!: any
  @Prop() contacts!: any
}
