import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { fetchMetaTags, recipesService } from '@/services'
import { RecipesState } from './types'
import { getRouter } from '@/router'

export const actions: ActionTree<RecipesState, RootState> = {
  async meta(_, { page }) {
    const { params } = getRouter().currentRoute
    await fetchMetaTags(page, params)
  },

  async fetch({ commit }, payload) {
    const widgets = (await recipesService(payload)) as any
    if (payload === undefined) {
      commit('widgets', { widgets })
    } else {
      const model = { ...widgets }
      commit('current', { model })
    }
  },
}
