import { Scene as Scene3D } from 'three'
import { css } from '@/webgl/hooks/utils'
import { MediaQ } from '@/constants'

export class Scene {
  webgl: Scene3D
  css3d: Scene3D
  css2d: Scene3D

  constructor() {
    this.webgl = new Scene3D()

    this.css3d = new Scene3D()
    this.css3d.scale.setScalar(0.0032)

    this.css2d = new Scene3D()
    this.css2d.scale.setScalar(1)
  }

  get css(): Scene3D {
    return this[css()]
  }

  get scale(): number {
    return this.css.scale.x
  }

  // eslint-disable-next-line
  setSize(width: number, height: number) {
    const scale3d = MediaQ.MD > width ? 0.006 : 0.0032
    const scale2d = MediaQ.MD > width ? 2 : 1
    this.css3d.scale.setScalar(scale3d)
    this.css2d.scale.setScalar(scale2d)
  }
}
