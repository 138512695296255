/* eslint-disable */
import { addAsset } from './cache'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { LoadingManager, TextureLoader, Mesh, RepeatWrapping } from 'three'
import { getAsset } from '..'

const preload = (store: string[], limit: number) => {
  return new Promise<void>((resolve) => {
    let count = 0
    const load = (index: number) => {
      const src = store[index]
      const img = new Image()
      img.src = src
      img.onload = () => {
        ++count
        if (count < limit) load(count)
        else resolve()
      }
    }

    load(count)
  })
}

export const fetchCollectionImages = (collection: any) => {
  const store: string[] = []

  if (collection instanceof Array) {
    for (const widget of collection) {
      if (widget.poster) {
        store.push(widget.poster)
      }

      if (widget.image) {
        store.push(widget.image.source)
      }

      if (widget.articles) {
        for (const article of widget.articles) {
          store.push(article.image.source)
        }
      }

      if (widget.images) {
        for (const image of widget.images) {
          if (image.source) {
            store.push(image.source)
          } else if (image.sources) {
            store.push(image.sources[0])
          }
        }
      }
    }
  } else {
    if (collection.image) {
      store.push(collection.image.source)
    }
  }

  return new Promise<void>(async (resolve) => {
    if (store.length === 1) preload(store, 1).then(() => resolve())
    else if (store.length > 1) preload(store, 2).then(() => resolve())
    else resolve()
  })
}

export const fetchNextPackAssets = (pack: any) => {
  const manager = new LoadingManager()
  const textureLoader = new TextureLoader(manager)
  const geometryLoader = new FBXLoader(manager)

  const { assets, config } = pack
  const { geometry, texture, normal, specular, pattern, leafs } = assets
  const { shadow } = config

  pack.theme.geometry = geometry
  pack.theme.texture = texture
  pack.theme.normal = normal
  pack.theme.pattern = pattern
  pack.theme.roughness = specular
  pack.theme.leafs = []
  pack.theme.shadow = shadow

  if (!getAsset(geometry)) {
    geometryLoader.load(geometry, (result) => {
      const mesh = result.children[0] as Mesh
      addAsset(geometry, mesh.geometry)
    })
  }

  if (!getAsset(normal)) {
    textureLoader.load(normal, (textureInstance) => addAsset(normal, textureInstance))
  }

  textureLoader.load(texture, (textureInstance) => addAsset(texture, textureInstance))
  textureLoader.load(specular, (textureInstance) => addAsset(specular, textureInstance))
  textureLoader.load(pattern, (textureInstance) => {
    textureInstance.wrapS = RepeatWrapping
    textureInstance.wrapT = RepeatWrapping
    addAsset(pattern, textureInstance)
  })

  for (let j = 0; j < leafs.length; ++j) {
    const leaf = leafs[j]
    const leafKey = `${texture}Leaf${j + 1}`
    pack.theme.leafs.push(leafKey)
    textureLoader.load(leaf, (texture) => addAsset(leafKey, texture))
  }
  return new Promise<void>((resolve) => {
    manager.onProgress = (url, loaded, total) => {
      if (loaded === total) {
        resolve()
      }
    }
  })
}

export const fetchAssets = (collection: any[], { product }: any) => {
  const sorted = [...collection]
  sorted.sort(({ route }) => (route === product ? -1 : 1))

  const manager = new LoadingManager()

  const textureLoader = new TextureLoader(manager)
  const geometryLoader = new FBXLoader(manager)

  for (let i = 0; i < sorted.length; ++i) {
    const model = sorted[i]
    const index = collection.indexOf(model)

    const { geometry, texture, normal, specular, pattern, leafs } = model.assets

    const textureKey = `colorMap${index + 1}`
    const patternKey = `bgPattern${index + 1}`
    const roughnessKey = `roughnessMap${index + 1}`
    const geometryKey = `geometry${index + 1}`
    const normalKey = `normalMap${index + 1}`

    model.theme.texture = textureKey
    model.theme.pattern = patternKey
    model.theme.normal = normalKey
    model.theme.roughness = roughnessKey
    model.theme.geometry = geometryKey
    model.theme.leafs = []

    textureLoader.load(texture, (texture) => addAsset(textureKey, texture))
    textureLoader.load(specular, (texture) => addAsset(roughnessKey, texture))
    textureLoader.load(normal, (texture) => addAsset(normalKey, texture))
    textureLoader.load(pattern, (texture) => {
      texture.wrapS = RepeatWrapping
      texture.wrapT = RepeatWrapping
      addAsset(patternKey, texture)
    })

    geometryLoader.load(geometry, (result) => {
      const mesh = result.children[0] as Mesh
      addAsset(geometryKey, mesh.geometry)
    })

    for (let j = 0; j < leafs.length; ++j) {
      const leaf = leafs[j]
      const leafKey = `${textureKey}Leaf${j + 1}`
      model.theme.leafs.push(leafKey)
      textureLoader.load(leaf, (texture) => addAsset(leafKey, texture))
    }
  }

  return new Promise<void>((resolve) => {
    const limit = 5
    manager.onProgress = (url, loaded, total) => {
      //console.log({url, loaded, total}, loaded > limit)
      if (loaded > limit) {
        resolve()
      }
    }
  })
}
