import Vue from 'vue'
import Vuex from 'vuex'

import { products } from './modules/products'
import { locator } from './modules/locator'
import { recipes } from './modules/recipes'
import { router } from './modules/router'
import { scene } from './modules/scene'
import { app } from './modules/app'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    products,
    locator,
    recipes,
    router,
    scene,
    app,
  },
})
