import { gsap } from 'gsap'
import { State } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'

@Component
export class LoaderTransitions extends Vue {
  @State('router') router!: any

  $refs!: {
    logo: HTMLElement
    loop: Vue
  }

  enter(el: HTMLElement, done: () => void) {
    const { logo, loop } = this.$refs
    const landing = !this.router.from.name
    const delay = landing ? 0 : 0.6
    const duration = landing ? 0.6 : 0.6
    const ease = landing ? 'power2.inOut' : 'power2.in'
    gsap
      .timeline({ delay, onComplete: done, defaults: { transformOrigin: 'left bottom' } })
      .fromTo(logo, { autoAlpha: 0 }, { duration, autoAlpha: 1, ease }, '<')
      .fromTo(loop.$el, { autoAlpha: 0 }, { duration, autoAlpha: 1, ease }, '<')
  }

  leave(el: HTMLElement, done: () => void) {
    const { logo, loop } = this.$refs
    gsap.killTweensOf(logo)
    gsap
      .timeline({ delay: 0.4, onComplete: done, defaults: { transformOrigin: 'left top' } })
      .to(logo, { duration: 0.6, autoAlpha: 0, ease: 'expo.out' }, '<')
      .to(loop.$el, { duration: 0.3, autoAlpha: 0, ease: 'expo.out' }, '<')
  }
}
