import Vue from 'vue'

class Scroller extends Vue {
  count = 0
  enable = false

  delta = { x: 0, y: 0 }
  time = { now: 0, last: 0 }
  direction = { prev: 0, next: 1 }

  hasWheelEvent = 'onwheel' in document
  hasMouseWheelEvent = 'onmousewheel' in document

  bindMouseWheel = this.onMouseWheel.bind(this)
  bindWheel = this.onWheel.bind(this)

  constructor() {
    super()

    if (this.$device.mobile) return

    if (this.hasWheelEvent) document.addEventListener('wheel', this.bindMouseWheel, this.$passive)
    if (this.hasMouseWheelEvent)
      document.addEventListener('mousewheel', this.bindWheel, this.$passive)
  }

  onMouseWheel(event: any) {
    this.delta.x = event.wheelDeltaX || event.deltaX * -1
    this.delta.y = event.wheelDeltaY || event.deltaY * -1
    this.wheel(event)
  }

  onWheel(event: any) {
    this.delta.x = event.wheelDeltaX ? event.wheelDeltaX : 0
    this.delta.y = event.wheelDeltaY ? event.wheelDeltaY : event.wheelDelta
    this.wheel(event)
  }

  wheel(event: any) {
    this.swipe(event)
    this.adjustDelta(event)
    const delta = this.delta
    const direction = delta.y > 0 ? -1 : 1
    this.direction.prev = this.direction.next
    this.direction.next = direction
    this.$emit('wheel', {
      direction,
      event,
      delta,
    })
  }

  swipe(event: any) {
    this.enable = true
    this.time.now = Date.now()
    if (this.count < 10 && this.time.now - this.time.last < 100) {
      this.count++
    } else {
      if (this.time.now - this.time.last > 100) this.count = 0
      else this.enable = false
    }
    this.time.last = Date.now()
    if (this.enable) {
      this.adjustDelta(event)
      const delta = this.delta
      const direction = delta.y > 0 ? -1 : 1
      this.direction.prev = this.direction.next
      this.direction.next = direction
      const changed = this.direction.prev != this.direction.next
      this.$emit('swipe', {
        direction,
        changed,
        event,
        delta,
      })
    }
  }

  adjustDelta(event: any) {
    const { os, browser } = this.$device.system

    if ('mac' == os) {
      if ('firefox' == browser) this.delta.y *= 40
      if (this.$fn.enhancedIncludes(browser, ['chrome', 'safari'])) this.delta.y *= 0.5
    }

    if ('windows' == os) {
      if ('firefox' == browser && 1 === event.deltaMode) this.delta.y *= 50
      if (this.$fn.enhancedIncludes(browser, ['chrome'])) this.delta.y *= 0.6
    }
  }

  dispose() {
    if (this.hasWheelEvent) document.removeEventListener('wheel', this.bindMouseWheel)
    if (this.hasMouseWheelEvent) document.removeEventListener('mousewheel', this.bindWheel)
  }
}

Vue.use(() => (Vue.prototype.$scroller = new Scroller()))

declare module 'vue/types/vue' {
  interface Vue {
    $scroller: Scroller
  }
}
