






















import i18n from '@/i18n'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class LangSwitch extends Vue {
  current = i18n.locale
  panelOpen = false

  get languages() {
    return Object.values(this.$t('languages')).filter((locale) => locale.value !== i18n.locale)
  }

  get label() {
    return this.$t(`languages.${i18n.locale}.label`)
  }

  @Watch('current')
  onChange(locale: string) {
    this.$emit('change', locale)
    this.clear()
  }

  clear() {
    this.panelOpen = false
  }

  mounted() {
    document.body.addEventListener('click', this.clear)
  }

  beforeDestroy() {
    document.body.removeEventListener('click', this.clear)
  }
}
