






import { gsap } from 'gsap'
import { Component, Vue, Prop } from 'vue-property-decorator'
import SmoothScrollbar from 'smooth-scrollbar'

type SharedScrollbar = SmoothScrollbar | HTMLElement | null

@Component
export default class Scroller extends Vue {
  @Prop({ type: Number, default: 0 }) position!: number
  @Prop({ type: Number, default: 0.1 }) damping!: number
  @Prop({ type: Number, default: 20 }) thumbMinSize!: number
  @Prop({ type: Boolean, default: true }) renderByPixels!: boolean
  @Prop({ type: Boolean, default: false }) alwaysShowTracks!: boolean
  @Prop({ type: Boolean, default: true }) continuousScrolling!: boolean

  timeline!: gsap.core.Timeline

  instance!: SharedScrollbar

  $el!: HTMLElement

  scrollTo(anchor: string) {
    const defaults = { duration: 1.8, ease: 'expo.inOut' }
    const target = document.getElementById(anchor)
    const scrollTop = this.scrollTop()
    const y = this.limitScroll(target ? target.offsetTop : 0)
    const state = { y: scrollTop }
    if (this.timeline) this.timeline.kill()
    this.timeline = gsap
      .timeline({
        defaults,
        onStart: () => {
          this.$state.smoothScroll = true
        },
        onComplete: () => {
          gsap.delayedCall(0.1, () => {
            this.$state.smoothScroll = false
          })
        },
      })
      .to(state, {
        y,
        onUpdate: () => {
          this.scrollTop(state.y)
        },
      })
  }

  scrollTop(value?: number) {
    if (value !== undefined) {
      if (this.instance instanceof HTMLElement) this.instance.scrollTop = value
      else this.instance?.scrollTo(0, value)
    }

    return this.instance instanceof HTMLElement
      ? (this.instance.scrollTop as number)
      : (this.instance?.offset.y as number)
  }

  limitScroll(offset: number) {
    const y =
      this.instance instanceof HTMLElement
        ? Math.min(offset, this.instance.scrollHeight as number)
        : Math.min(offset, this.instance?.limit.y as number)
    return y
  }

  onSmoothScroll({ offset, limit }: any) {
    this.$emit('scroll', { offset, limit })
  }

  onScroll() {
    const offset = { x: 0, y: this.$el.scrollTop }
    this.$emit('scroll', { offset })
  }

  mounted() {
    this.instance = !this.$device.mobile ? SmoothScrollbar.init(this.$el, this.$props) : this.$el
    if (this.instance instanceof HTMLElement)
      this.instance.addEventListener('scroll', this.onScroll)
    else this.instance.addListener(this.onSmoothScroll)
    this.$fn.scrollTop = this.scrollTop
    this.$fn.scrollTo = this.scrollTo
  }

  beforeDestroy() {
    if (this.instance instanceof HTMLElement)
      this.instance.removeEventListener('scroll', this.onScroll)
    else this.instance?.removeListener(this.onSmoothScroll)
    this.instance = null
  }
}
