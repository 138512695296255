/* eslint-disable */
import axios from 'axios'

const dataLayer = (<any>window)['dataLayer']

export const gtagPageView = (page_path: string) => {
  const scope = window as any
  if (scope.gtag) {
    scope.gtag('config', process.env.VUE_APP_GTM_ID, { page_path })
  }
}

export const teadsPageView = (page_path: string) => {
  const scope = window as any
  if (scope.teads_e) {
    scope.teads_e = [
      ...scope.teads_e,
      ...[
        {
          conversionType: page_path,
        },
      ],
    ]
  }
}

export const addTeadsPixel = async () => {
  const teads = await axios.get(
    `${process.env.VUE_APP_SEO_BASE}teads-pixel.php?api=1&v=${Date.now()}`
  )
  const { adv_src, adv_id } = teads as any
  const script = document.createElement('script')
  script.async = true
  script.src = adv_src
  document.head.appendChild(script)
  ;(<any>window).teads_e = (<any>window).teads_e || []
  ;(<any>window).teads_adv_id = adv_id
}
