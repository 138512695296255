import { gsap } from 'gsap'
import { Component, Vue } from 'vue-property-decorator'

@Component
export class LogoTransitions extends Vue {
  logoEnter(el: HTMLElement, done: () => void) {
    gsap
      .timeline({ onComplete: done })
      .from(el, { duration: 0.6, yPercent: -100, ease: 'expo.out' })
  }

  logoLeave(el: HTMLElement, done: () => void) {
    gsap
      .timeline({ onComplete: done })
      .to(el, { duration: 1.4, yPercent: -100, ease: 'expo.inOut' })
  }
}

@Component
export class IconsTransitions extends Vue {
  iconEnter(el: HTMLElement, done: () => void) {
    gsap
      .timeline({ delay: 0.6, onComplete: done })
      .from(el, { duration: 1, yPercent: 100, ease: 'expo.out' })
  }

  iconLeave(el: HTMLElement, done: () => void) {
    gsap.timeline({ onComplete: done }).to(el, { duration: 0.6, yPercent: 100, ease: 'expo.out' })
  }
}

@Component
export class MenuTransitions extends Vue {
  $refs!: {
    mainNavLabels: any[]
    subsNavLabels: any[]
    mainNavLines: any[]
    subsNavLines: any[]
    footerSwitch: any
    footerLinks: any
    footerLang: any
    scroller: any
  }

  enter(el: HTMLElement, done: () => void) {
    const { mainNavLabels, subsNavLabels, mainNavLines, subsNavLines, footerLinks, footerSwitch } =
      this.$refs
    const { socials, email, phone, adress } = footerLinks.$refs
    const { switchButton } = footerSwitch.$refs
    const { icons } = socials.$refs

    gsap
      .timeline({ onComplete: done })
      .add(gsap.from(el.children[1], { duration: 1.2, xPercent: 120, ease: 'expo.out' }), '<')
      .add(
        gsap
          .timeline({ defaults: { ease: 'expo.out', stagger: 0.08 } })
          .fromTo(
            [...mainNavLabels, ...subsNavLabels],
            { x: 20, yPercent: 150, rotate: 3 },
            { x: 0, yPercent: 0, rotate: 0, transformOrigin: 'left top', duration: 0.8 },
            '<'
          )
          .fromTo(
            [...mainNavLines, ...subsNavLines],
            { scaleX: 0 },
            { scaleX: 1, transformOrigin: 'left center', duration: 1.2 },
            '<+.2'
          ),
        '<'
      )
      .add(
        gsap
          .timeline({ defaults: { ease: 'expo.out', stagger: 0.08 } })
          .fromTo(
            [switchButton, ...icons, email],
            { x: 20, yPercent: 100 },
            { x: 0, yPercent: 0, duration: 0.8 },
            '<'
          )
          .fromTo(
            [phone, adress],
            { x: 20, yPercent: 40, autoAlpha: 0 },
            { x: 0, yPercent: 0, autoAlpha: 1, duration: 0.8 },
            '<+.3'
          ),
        '<+.4'
      )
  }

  leave(el: HTMLElement, done: () => void) {
    gsap
      .timeline({ onComplete: done })
      .to(el.children[1], { duration: 0.8, xPercent: 100, ease: 'expo.out' })
  }
}
