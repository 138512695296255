









import { State } from 'vuex-class'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Canvas from '@/components/organsims/Canvas.vue'
import Routes from '@/components/organsims/Routes.vue'
import Header from '@/components/organsims/Header.vue'
import Loader from '@/components/molecules/Loader.vue'

@Component({
  components: {
    Canvas,
    Header,
    Routes,
    Loader,
  },
})
export default class App extends Vue {
  @State('router') router!: any

  fetching = false

  @Watch('$route')
  onRouteChange() {
    const { to, from } = this.router
    if (to.meta.name !== from.meta.name) this.fetching = true
  }

  @Watch('$state.fetching')
  onViewFetching(fetching: boolean) {
    this.fetching = fetching
  }

  get pointer() {
    return this.$device.mobile ? 'touch' : 'no-touch'
  }

  get section() {
    return this.$route.meta?.name
  }
}
