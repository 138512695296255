import axios, { AxiosResponse } from 'axios'
import i18n from '@/i18n'

const { VUE_APP_API_BASE } = process.env

export const localeService = (lang: string) =>
  axios.get(`${VUE_APP_API_BASE}locales.php?api=1&lang=${lang}&v=${Date.now()}`)

export const productsService = () =>
  axios.get(`${VUE_APP_API_BASE}products.php?api=1&lang=${i18n.locale}&v=${Date.now()}`)

export const aboutUsService = () =>
  axios.get(`${VUE_APP_API_BASE}about-us.php?api=1&lang=${i18n.locale}&v=${Date.now()}`)

export const contactsService = () =>
  axios.get(`${VUE_APP_API_BASE}contacts.php?api=1&lang=${i18n.locale}&v=${Date.now()}`)

export const planetFarmsService = () =>
  axios.get(`${VUE_APP_API_BASE}planet-farms.php?api=1&lang=${i18n.locale}&v=${Date.now()}`)

export const prodProcessService = () =>
  axios.get(`${VUE_APP_API_BASE}prod-process.php?api=1&lang=${i18n.locale}&v=${Date.now()}`)

export const sustainabilityService = () =>
  axios.get(`${VUE_APP_API_BASE}sustainability.php?api=1&lang=${i18n.locale}&v=${Date.now()}`)

export const storeLocatorService = () =>
  axios.get(`${VUE_APP_API_BASE}store-locator.php?api=1&lang=${i18n.locale}&v=${Date.now()}`)

export const recipesService = (slug?: string) => {
  if (!slug)
    return axios.get(`${VUE_APP_API_BASE}recipes.php?api=1&lang=${i18n.locale}&v=${Date.now()}`)
  else
    return axios.get(
      `${VUE_APP_API_BASE}recipes.php?slug=${slug}&api=1&lang=${i18n.locale}&v=${Date.now()}`
    )
}

const parseTooltip = (data: any) => {
  if (data instanceof Array) {
    const tooltip = data.find(({ theme }: any) => theme && theme.type === 'tooltip')
    if (tooltip) {
      const tooltips = data
        .filter((widget) => (widget.tooltip ? widget : false))
        .map((widget) => ({ ...widget.tooltip, index: data.indexOf(widget) }))
      tooltip.collection = tooltips
    }
  }
}

axios.interceptors.response.use(({ data }: AxiosResponse) => {
  parseTooltip(data)
  return data
})
