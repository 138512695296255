export default [
  {
    path: 'keep-in-touch',
    name: 'contacts',
    meta: { name: 'contacts', page: 'keep-in-touch' },
    component: () => import(/* webpackChunkName: "contacts" */ '@/views/Contacts.vue'),
  },
  {
    path: 'guess-what',
    name: 'about-us',
    meta: { name: 'about-us', page: 'guess-what' },
    component: () => import(/* webpackChunkName: "about-us" */ '@/views/AboutUs.vue'),
  },
  {
    path: 'follow-us-in-the-kitchen',
    name: 'recipes',
    meta: { name: 'recipes', page: 'follow-us-in-the-kitchen' },
    component: () => import(/* webpackChunkName: "recipes" */ '@/views/Recipes.vue'),
  },
  {
    path: 'follow-us-in-the-kitchen/:recipe',
    name: 'recipe-leaf',
    meta: { name: 'recipe-leaf', page: 'recipe-leaf' },
    props: true,
    component: () => import(/* webpackChunkName: "recipes" */ '@/views/RecipeLeaf.vue'),
  },
  {
    path: 'nurturing-the-future',
    name: 'sustainability',
    meta: { name: 'sustainability', page: 'nurturing-the-future' },
    component: () => import(/* webpackChunkName: "sustainability" */ '@/views/Sustainability.vue'),
  },
  {
    path: 'our-field',
    name: 'prod-process',
    meta: { name: 'prod-process', page: 'our-field' },
    component: () => import(/* webpackChunkName: "prod-process" */ '@/views/ProdProcess.vue'),
  },
  {
    path: 'our-world',
    name: 'planet-farms',
    meta: { name: 'planet-farms', page: 'our-world' },
    component: () => import(/* webpackChunkName: "planet-farms" */ '@/views/PlanetFarms.vue'),
  },
  {
    path: '',
    name: 'products',
    meta: { name: 'products', page: 'products', hasLeaf: true },
    component: () => import(/* webpackChunkName: "products" */ '@/views/Products.vue'),
  },
  {
    path: 'products/:product',
    name: 'product-leaf',
    meta: { name: 'products', page: 'product-leaf', isLeaf: true },
    component: () => import(/* webpackChunkName: "products" */ '@/views/ProductLeaf.vue'),
  },
]
