







import { Component, Vue, Prop } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

@Component
export default class LogoIcon extends Vue {
  @Prop() to!: RawLocation
}
