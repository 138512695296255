













































import { gsap } from 'gsap'
import { Getter, State, Action } from 'vuex-class'
import { SceneState } from '@/store/modules/scene/types'
import { CategoryState, ProductState, SceneTheme } from '@/store/modules/products/types'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { StoreLocator } from '@/constants'
import Resizer from '@/observable/Resizer'
import debounce from 'debounce'

@Component({
  components: {
    CategoryText: () =>
      import(/* webpackChunkName: "webgl" */ '@/webgl/components/Text/Category'),
    ProductLeafs: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Leafs'),
    ProductPack: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Product'),
    Background: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Background'),
    Patterns: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Patterns'),
    Lights: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Lights'),
    Canvas: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Canvas.vue'),
    Resizer,
  },
})
export default class Stage extends Vue {
  @Getter('products/next') next!: number
  @Getter('products/prev') prev!: number
  @Getter('products/theme') theme!: SceneTheme
  @Getter('products/fetched') fetched!: boolean
  @Getter('products/direction') direction!: number
  @Getter('products/current') current!: ProductState
  @Getter('products/category') category!: CategoryState
  @Getter('products/collection') collection!: ProductState[]
  @Action('products/setTheme') setTheme!: (theme: SceneTheme) => void
  @Action('products/update') update!: (index: number) => void
  @Getter('products/find') find!: (route: string) => number

  @Getter('app/menuopen') menuopen!: boolean

  @State('router') router!: any
  @State('scene') scene!: SceneState

  debounceSyncState = debounce(this.syncState, 100)

  timeline!: gsap.core.Timeline

  needsCategoryUpdate = false

  transition = false

  forcedSync = true

  $refs!: {
    webgl: HTMLElement
  }

  animation = {
    pattern: {
      progress: 1,
    },
    back: {
      transition: 0,
      progress: 1,
      length: 0.5,
    },
    text: {
      prev: { progress: 1 },
      next: { progress: 1 },
    },
    pack: {
      progress: { next: 1, prev: 1 },
      position: 0,
      rotation: 0,
      scale: 1,
    },
    leafs: {
      progress: 1,
      position: 0,
      opacity: 0,
      scale: 0,
    },
  }

  get toLeaf() {
    return !!this.$route.meta?.isLeaf
  }

  get mousemove() {
    return !this.$device.mobile
  }

  get smoothSync() {
    const { to, from } = this.router
    return !!((from.meta.hasLeaf && to.meta.isLeaf) || (from.meta.isLeaf && to.meta.hasLeaf))
  }

  get preventSceneSync() {
    const { to, from } = this.router
    const toName = to && to.name
    const toHash = to && to.hash
    const fromName = from && from.name
    const fromHash = from && from.hash
    return (
      (StoreLocator.HASH === toHash && from !== undefined) ||
      (StoreLocator.HASH === fromHash && toName === fromName)
    )
  }

  @Watch('$route', { immediate: true })
  onRouteChange() {
    if (this.preventSceneSync || !this.fetched) return

    const { meta } = this.$route
    if (this.fetched) {
      if (meta?.isLeaf || meta?.hasLeaf) {
        this.setup()
      }
    }

    if (meta?.isLeaf || meta?.hasLeaf) {
      this.gl.resume()
    } else {
      this.gl.clear()
    }
  }

  @Watch('fetched', { immediate: true })
  setup() {
    if (!this.fetched) return

    const { params } = this.$route

    if (params.product) {
      const index = this.find(params.product)
      if (index !== this.next) this.update(this.find(params.product))
      else {
        this.forcedSync = true
        this.updateState(this.next)
      }
    } else {
      this.forcedSync = true
      this.updateState(this.next)
    }
  }

  @Watch('next')
  // eslint-disable-next-line
  updateState(next: number) {
    this.debounceSyncState()
  }

  @Watch('theme')
  onThemeUpdate(next: SceneTheme, prev: SceneTheme) {
    this.needsCategoryUpdate = (next && next.label) !== (prev && prev.label)
  }

  @Watch('$state.scrollOffset')
  onSmoothScroll(offset: number) {
    if (!this.transition) {
      gsap
        .timeline()
        .to(
          this.animation.pack,
          { duration: 0.6, rotation: -offset * 0.006, ease: 'power2.out' },
          '<'
        )
        .to(
          this.animation.leafs,
          { duration: 0.6, position: -offset * 0.002, ease: 'power2.out' },
          '<'
        )
    }
  }

  async syncState() {
    this.setTheme({
      ...this.current.theme,
      label: this.category.name,
    })

    await this.$nextTick()

    this.syncScene()
    this.forcedSync = false
  }

  syncScene() {
    const onStart = () => {
      this.transition = true
    }
    const onUpdate = () => {
      this.transition = this.timeline.progress() < 0.5
    }

    this.timeline = gsap.timeline({ onStart, onUpdate }).timeScale(1)

    /**
     * pattern transitions
     */
    if (!(this.smoothSync && this.forcedSync))
      this.timeline.add(
        gsap.timeline().fromTo(
          this.animation.pattern,
          { progress: this.direction > 0 ? 0 : 1 },
          {
            progress: this.direction > 0 ? 1 : 0,
            duration: this.toLeaf ? 2.4 : 1.8,
            ease: 'power1.out',
          },
          '<'
        ),
        '<'
      )

    /**
     * background transitions
     */
    this.timeline.add(
      gsap
        .timeline()
        .to(
          this.animation.back,
          { length: this.toLeaf ? 1 : 0.5, duration: 2, ease: 'expo.out' },
          '<'
        ),
      '<'
    )

    if (!(this.smoothSync && this.forcedSync))
      this.timeline.add(
        gsap.timeline().fromTo(
          this.animation.back,
          { progress: this.direction > 0 ? 0 : 1 },
          {
            progress: this.direction > 0 ? 1 : 0,
            duration: this.toLeaf ? 2.4 : 1.8,
            ease: 'power1.out',
          },
          '<'
        ),
        '<'
      )
    else
      this.timeline.add(
        gsap.timeline().fromTo(
          this.animation.back,
          { transition: this.toLeaf ? 0 : 1 },
          {
            transition: this.toLeaf ? 1 : 0,
            duration: this.toLeaf ? 2.4 : 1.8,
            ease: 'power1.out',
          },
          '<'
        ),
        '<'
      )

    /**
     * category transitions
     */
    this.timeline.add(
      gsap
        .timeline()
        .fromTo(
          this.animation.text.prev,
          { progress: 1 },
          {
            progress: 0,
            duration: 0.8 * (this.needsCategoryUpdate ? 1 : 0),
            ease: 'power1.out',
          },
          '<'
        )
        .fromTo(
          this.animation.text.next,
          { progress: 0 },
          {
            progress: 1,
            duration: 1 * (this.needsCategoryUpdate ? 1 : 0),
            ease: 'power1.out',
          },
          '<+.4'
        ),
      '<'
    )

    /**
     * package transitions
     */
    this.timeline.add(
      gsap
        .timeline()
        .to(this.animation.pack, { position: 0, duration: 1.8, ease: 'expo.out' }, '<')
        .to(this.animation.pack, { rotation: 0, duration: 1, ease: 'power2.out' }, '<')
        .to(
          this.animation.pack,
          { scale: this.toLeaf ? 0.85 : 1, duration: 1, ease: 'power2.out' },
          '<'
        ),
      '<'
    )

    if (!this.forcedSync)
      this.timeline.add(
        gsap.timeline().fromTo(
          this.animation.pack,
          {
            rotation: this.rotationOffset(this.animation.pack.rotation, this.direction),
          },
          { rotation: 0, duration: 1.6, ease: 'back.inOut' },
          '<'
        ),
        '<'
      )

    if (!(this.smoothSync && this.forcedSync)) {
      gsap.killTweensOf(this.animation.pack.progress)

      this.timeline.add(
        gsap
          .timeline()
          .fromTo(
            this.animation.pack.progress,
            { prev: this.direction > 0 ? 0 : 1 },
            {
              prev: this.direction > 0 ? 1 : 0,
              immediateRender: false,

              duration: 2,
              ease: 'power1.out',
            },
            '<'
          )
          .fromTo(
            this.animation.pack.progress,
            { next: this.direction > 0 ? 0 : 1 },
            {
              next: this.direction > 0 ? 1 : 0,
              immediateRender: false,
              duration: 2,
              ease: 'power1.out',
            },
            `<+${0.6}`
          ),
        '<'
      )
    }

    /**
     * leafs transitions
     */
    this.timeline.add(
      gsap
        .timeline()
        .fromTo(
          this.animation.leafs,
          { progress: this.direction > 0 ? 0 : 1 },
          {
            progress: this.direction > 0 ? 1 : 0,
            duration: 2,
            ease: 'power1.out',
          },
          '<'
        )
        .to(
          this.animation.leafs,
          { position: this.toLeaf ? 0 : 2, duration: 2, ease: 'expo.out' },
          '<'
        )
        .to(
          this.animation.leafs,
          { opacity: this.toLeaf ? 1 : 0, duration: 1, ease: 'expo.out' },
          '<'
        )
        .to(
          this.animation.leafs,
          { scale: this.toLeaf ? 1 : 0, duration: 1, ease: 'expo.out' },
          '<'
        ),
      '<'
    )
  }

  rotationOffset(rotation: number, direction: number) {
    const PI2 = Math.PI * 2
    const offset = direction > 0 ? Math.abs(rotation) : rotation
    return direction > 0 ? PI2 - offset : offset - PI2
  }

  resize({ width, height }: any) {
    this.gl.resize(width, height)
  }

  pointerSwipe({ axis, direction }: any) {
    if (
      this.$state.transition ||
      this.menuopen ||
      this.toLeaf ||
      !this.$state.scrollSnap ||
      !this.$device.mobile ||
      !this.collection[this.next + direction.y] ||
      !this.collection[this.next + direction.y].loaded ||
      axis.x
    )
      return
    this.update(this.next + direction.y)
  }

  scrollSwipe({ direction }: any) {
    if (
      this.$state.transition ||
      this.menuopen ||
      this.toLeaf ||
      !this.$state.scrollSnap ||
      !this.collection[this.next + direction] ||
      !this.collection[this.next + direction].loaded
    )
      return
    this.update(this.next + direction)
  }

  keyboardNext() {
    if (
      this.$state.transition ||
      this.menuopen ||
      this.toLeaf ||
      !this.$state.scrollSnap ||
      !this.collection[this.next + 1] ||
      !this.collection[this.next + 1].loaded
    )
      return
    this.update(this.next + 1)
  }

  keyboardPrev() {
    if (
      this.$state.transition ||
      this.menuopen ||
      this.toLeaf ||
      !this.$state.scrollSnap ||
      !this.collection[this.next - 1] ||
      !this.collection[this.next - 1].loaded
    )
      return
    this.update(this.next - 1)
  }

  addListeners() {
    this.$pointer.$on('swipe', this.pointerSwipe)
    this.$scroller.$on('swipe', this.scrollSwipe)
    this.$keyboard.$on('prev', this.keyboardPrev)
    this.$keyboard.$on('next', this.keyboardNext)
  }

  removeListeners() {
    this.$pointer.$off('swipe', this.pointerSwipe)
    this.$scroller.$off('swipe', this.scrollSwipe)
    this.$keyboard.$off('prev', this.keyboardPrev)
    this.$keyboard.$off('next', this.keyboardNext)
  }

  mounted() {
    this.$refs.webgl.appendChild(this.gl.renderer.domElement)
    this.$el.appendChild(this.gl.renderer.css.domElement)
    this.addListeners()
  }

  beforeDestroy() {
    this.removeListeners()
    if (this.$refs.webgl.children.length)
      this.$refs.webgl.removeChild(this.gl.renderer.domElement)
  }
}
