import Vue from 'vue'
import { Renderer, Camera, Scene } from './core'

class Three extends Vue {
  renderer: Renderer
  camera: Camera
  scene: Scene

  enable = true

  constructor() {
    super()

    this.renderer = new Renderer()
    this.camera = new Camera()
    this.scene = new Scene()
  }

  render() {
    this.renderer.render(this.scene, this.camera)
  }

  resize(width: number, height: number) {
    this.scene.setSize(width, height)
    this.camera.setSize(width, height)
    this.renderer.setSize(width, height)
  }

  resume() {
    this.enable = true
  }

  clear() {
    this.enable = false
  }
}

Vue.use(() => (Vue.prototype.gl = new Three()))

declare module 'vue/types/vue' {
  interface Vue {
    gl: Three
  }
}
