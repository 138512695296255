import { MutationTree } from 'vuex'
import { AppState } from './types'

export const mutations: MutationTree<AppState> = {
  loaded(state) {
    state.loaded = true
  },

  menuOpen(state, payload) {
    state.menuopen = payload
  },

  setCookies(state, payload) {
    state.cookies = payload
  },
}
