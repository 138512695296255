










import { State } from 'vuex-class'
import { StoreLocator } from '@/constants'
import { RoutesTransitions } from '@/animations'
import { Component, Watch } from 'vue-property-decorator'
import Locator from '@/components/organsims/Locator.vue'
import Columns from '@/components/molecules/Columns.vue'

@Component({
  components: {
    Locator,
    Columns,
  },
})
export default class Routes extends RoutesTransitions {
  @State('router') router!: any

  @Watch('$state.fetching')
  onFetching(fetching: boolean) {
    if (!fetching) this.reveal()
  }

  get showStoreLocator() {
    return StoreLocator.HASH === this.$route.hash
  }

  get transitionMode() {
    return this.needsColumns ? 'out-in' : ''
  }

  get needsColumns() {
    const { to, from } = this.router
    return from.meta ? to.meta.name !== from.meta.name : true
  }
}
