import axios from 'axios'

const { VUE_APP_API_BASE } = process.env

export const fetchMetaTags = async (page: string, { product, recipe, lang }: any) => {
  const response = product
    ? ((await axios.get(
        `${VUE_APP_API_BASE}meta.php?api=1&page=${page}&slug=${product}&lang=${lang}&v=${Date.now()}`
      )) as any)
    : recipe
    ? ((await axios.get(
        `${VUE_APP_API_BASE}meta.php?api=1&page=${page}&slug=${recipe}&lang=${lang}&v=${Date.now()}`
      )) as any)
    : ((await axios.get(
        `${VUE_APP_API_BASE}meta.php?api=1&page=${page}&lang=${lang}&v=${Date.now()}`
      )) as any)
  const meta = response[page]

  document
    .querySelector('meta[property="og:description"]')
    ?.setAttribute('content', meta.default.description)
  document.querySelector('meta[property="og:title"]')?.setAttribute('content', meta.default.title)

  document
    .querySelector('meta[name="twitter:description"]')
    ?.setAttribute('content', meta.default.description)
  document.querySelector('meta[name="twitter:title"]')?.setAttribute('content', meta.default.title)

  document
    .querySelector('meta[name="description"]')
    ?.setAttribute('content', meta.default.description)
  document.title = meta.default.title
}
