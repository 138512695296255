import '@/gsap'
import '@/vue/extends'
import '@/assets/scss/theme.scss'

import Vue from 'vue'
import i18n from '@/i18n'
import store from '@/store'
import App from '@/App.vue'
import { fetchRouter } from '@/router'

import '@/plugins/ScrollSnap'
import '@/plugins/Cookiebot'
import '@/plugins/Scroller'
import '@/plugins/Keyboard'
import '@/plugins/Pointer'
import '@/webgl/Three'

Vue.config.productionTip = false
;(async () => {
  const router = await fetchRouter()

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app')
})()
