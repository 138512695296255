import Vue from 'vue'
import { MathUtils } from 'three'

type PointerEvent = MouseEvent | TouchEvent

class Pointer extends Vue {
  last = { x: 0, y: 0 }
  dist = { x: 0, y: 0 }
  mouse = { x: 0, y: 0 }
  start = { x: 0, y: 0 }
  client = { x: 0, y: 0 }
  time = { now: 0, last: 0 }
  timestamp = Date.now()
  dragging = false
  lockAxis = null
  threshold = 4
  speed = 0

  bindStart = this.onStart.bind(this)
  bindMove = this.onMove.bind(this)
  bindEnd = this.onEnd.bind(this)
  bindLeave = this.onLeave.bind(this)

  constructor() {
    super()

    document.addEventListener('mouseleave', this.bindLeave, this.$passive)
    document.addEventListener('mousedown', this.bindStart, this.$passive)
    document.addEventListener('mousemove', this.bindMove, this.$passive)
    document.addEventListener('mouseup', this.bindEnd, this.$passive)

    document.addEventListener('touchstart', this.bindStart, this.$passive)
    document.addEventListener('touchmove', this.bindMove, this.$passive)
    document.addEventListener('touchend', this.bindEnd, this.$passive)
  }

  sync(event: any) {
    this.client.x = event.touches ? event.touches[0].clientX : event.clientX
    this.client.y = event.touches ? event.touches[0].clientY : event.clientY
  }

  syncDist() {
    this.dist.x = this.client.x - this.start.x
    this.dist.y = this.client.y - this.start.y
  }

  syncSpeed() {
    const now = Date.now()
    const elapsed = now - this.timestamp
    const dir = this.client.x - this.last.x > 0 ? 1 : -1
    const distance = Math.abs(this.client.x - this.last.x)
    this.speed = MathUtils.clamp(Math.round(distance / elapsed), 0, 8) * dir
    this.speed = !isNaN(this.speed) || isFinite(this.speed) ? this.speed : 0
    this.last.x = this.client.x
    this.timestamp = now
  }

  onStart(event: PointerEvent) {
    this.sync(event)

    this.dragging = true
    this.time.now = Date.now()
    this.start = { ...this.client }
    this.dist = { x: 0, y: 0 }
  }

  onMove(event: PointerEvent) {
    this.sync(event)
    this.syncSpeed()
    this.$emit('move')

    if (!this.dragging) return

    this.syncDist()
    this.$emit('drag', {
      direction: {
        x: this.dist.x > 0 ? -1 : 1,
        y: this.dist.y > 0 ? -1 : 1,
      },
    })
  }

  onEnd() {
    this.syncDist()

    const clicked = this.validateClick()
    const { x, y } = this.validateSwipe()

    const direction = {
      x: this.dist.x > 0 ? -1 : 1,
      y: this.dist.y > 0 ? -1 : 1,
    }

    if (clicked) this.$emit('click')
    else if (x || y)
      this.$emit('swipe', {
        axis: { x, y },
        direction,
      })

    this.clear()
  }

  onLeave() {
    this.clear()
  }

  clear() {
    this.dragging = false
    this.lockAxis = null
    this.speed = 0
  }

  validateDrag() {
    const distX = Math.abs(this.dist.x)
    const distY = Math.abs(this.dist.y)
    return {
      x: distX > distY && distX > this.threshold,
      y: distY > distX && distY > this.threshold,
    }
  }

  validateSwipe() {
    const validateDrag = this.validateDrag()
    const duration = Date.now() - this.time.now
    return {
      x: validateDrag.x && duration < 600, // && this.mobile
      y: validateDrag.y && duration < 600, // && this.mobile
    }
  }

  validateClick() {
    const distX = Math.abs(this.dist.x)
    const distY = Math.abs(this.dist.y)
    return distX < this.threshold && distY < this.threshold
  }

  dispose() {
    document.removeEventListener('mouseleave', this.bindLeave)
    document.removeEventListener('mousedown', this.bindStart)
    document.removeEventListener('mousemove', this.bindMove)
    document.removeEventListener('mouseup', this.bindEnd)

    document.removeEventListener('touchstart', this.bindStart)
    document.removeEventListener('touchmove', this.bindMove)
    document.removeEventListener('touchend', this.bindEnd)
  }
}

Vue.use(() => (Vue.prototype.$pointer = new Pointer()))

declare module 'vue/types/vue' {
  interface Vue {
    $pointer: Pointer
  }
}
