













import { Component, Prop } from 'vue-property-decorator'
import { LoaderTransitions } from '@/animations'
import Loop from '@/components/atoms/Loop.vue'

@Component({
  components: {
    Loop,
  },
})
export default class Loader extends LoaderTransitions {
  @Prop()
  active!: boolean
}
