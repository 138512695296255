import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { RecipesState } from './types'

export const getters: GetterTree<RecipesState, RootState> = {
  fetched: ({ fetched }) => fetched,

  current: ({ current }) => current,

  widgets: ({ widgets }) => widgets,

  collection: (_, { recipesWidget }) => (recipesWidget ? recipesWidget.list : []),

  recipesWidget: ({ widgets }) => widgets.find((widget: any) => 'recipes' === widget.theme.type),
}
