import Vue from 'vue'

class Cookiebot extends Vue {
  bindDecline = this.onDecline.bind(this)
  bindAccept = this.onAccept.bind(this)

  constructor() {
    super()

    window.addEventListener('CookiebotOnDecline', this.bindDecline, this.$passive)
    window.addEventListener('CookiebotOnAccept', this.bindAccept, this.$passive)
  }

  onDecline() {
    const Cookiebot = (window as any).Cookiebot
    if (Cookiebot) {
      //console.log('Cookiebot::onDecline', Cookiebot, Cookiebot.consent.marketing)
    }
  }

  onAccept() {
    const Cookiebot = (window as any).Cookiebot
    if (Cookiebot) {
      //console.log('Cookiebot::onAccept', Cookiebot, Cookiebot.consent.marketing)
    }
  }
}

Vue.use(() => (Vue.prototype.$scroller = new Cookiebot()))

declare module 'vue/types/vue' {
  interface Vue {
    $cookiebot: Cookiebot
  }
}
