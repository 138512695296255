import Vue, { VNode } from 'vue'
import appRoutes from '@/router/routes'
import VueRouter, { RouteConfig } from 'vue-router'
import { gtagPageView, teadsPageView } from '@/services'
import { fetchI18nLocale } from '@/i18n'
import store from '@/store'
import i18n from '@/i18n'

Vue.use(VueRouter)

let router: VueRouter

export const getRouter = () => router

export const fetchRouter = async () => {
  const html = document.querySelector('html')
  const lang = html?.getAttribute('lang') as string

  const messages = (await fetchI18nLocale(lang)) as any

  const navigation = [...messages.nav.main, ...messages.nav.subs]
    .filter(({ routes }) => routes !== undefined)
    .reverse() as any

  const AbstractComponent = () => ({
    render: (h: (el: string) => VNode) => h('router-view'),
  })

  const routes = [
    {
      path: '/',
      redirect: `/${i18n.locale}`,
    },
    {
      path: '/:lang',
      component: AbstractComponent(),
      children: [
        ...appRoutes.map((route, index) => {
          const item = navigation[index]
          const abstract = item.abstract
          const slugs = item.routes
          const path = slugs[lang]
          return {
            ...route,
            path,
            meta: {
              ...route.meta,
              abstract,
              slugs,
            },
          }
        }),
      ],
    },
  ] as RouteConfig[]

  router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  })

  const parseStoreRoute = ({ name, path, meta, hash, params }: any) => ({
    name,
    path,
    meta,
    hash,
    params,
  })

  router.beforeEach(async (to, from, next) => {
    await store.dispatch('app/fetch')
    await store.dispatch('app/openMenu', false)
    await store.dispatch('router/sync', {
      from: parseStoreRoute(from),
      to: parseStoreRoute(to),
    })
    teadsPageView(to.path)
    gtagPageView(to.path)
    next()
  })

  return router
}
