import { render, staticRenderFns } from "./Hamb.vue?vue&type=template&id=e16f0620&scoped=true&"
import script from "./Hamb.vue?vue&type=script&lang=ts&"
export * from "./Hamb.vue?vue&type=script&lang=ts&"
import style0 from "./Hamb.vue?vue&type=style&index=0&id=e16f0620&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e16f0620",
  null
  
)

export default component.exports