import { gsap } from 'gsap'

export const enterProducts = (el: HTMLElement, done: () => void) => done()

export const leaveProducts = (el: HTMLElement, done: () => void) => {
  gsap.delayedCall(0.4, done)
}

export const enterProductLeaf = (el: HTMLElement, done?: () => void) => {
  const back = el.querySelector('.back-btn')
  const name = el.querySelector('.name h1 span')
  const cta = el.querySelector('.name p button')
  const text = el.querySelectorAll('.text p span')
  const specs = el.querySelectorAll('.specs h3 span, .specs p span')

  gsap
    .timeline({ onComplete: done, delay: 0.12, defaults: { duration: 1.6, ease: 'expo.inOut' } })
    .fromTo(back, { y: 30, autoAlpha: 0 }, { y: 0, autoAlpha: 1 }, '<')
    .fromTo(name, { yPercent: 100, skewY: 8 }, { yPercent: 0, skewY: 0 }, '<')
    .fromTo(cta, { yPercent: 100, skewY: 8 }, { yPercent: 0, skewY: 0 }, '<+.2')
    .fromTo(text, { yPercent: 100, skewY: 6 }, { yPercent: 0, skewY: 0 }, '<+.2')
    .fromTo(specs, { yPercent: 100, skewY: 6 }, { yPercent: 0, skewY: 0 }, '<+.2')
}

export const leaveProductLeaf = (el: HTMLElement, done: () => void) => {
  const back = el.querySelector('.back-btn')
  const name = el.querySelector('.name h1 span')
  const cta = el.querySelector('.name p button')
  const text = el.querySelectorAll('.text p span')
  const specs = el.querySelectorAll('.specs h3 span, .specs p span')
  const arrow = el.querySelectorAll('.next-btn span, .next-btn svg')
  const footer = el.querySelector('footer')

  gsap
    .timeline({ onComplete: done, defaults: { duration: 0.8, ease: 'expo.inOut' } })
    .to(name, { yPercent: -100 }, '<')
    .to(cta, { yPercent: -100 }, '<+.05')
    .to(back, { y: -30, autoAlpha: 0 }, '<')
    .to(text, { yPercent: -100, stagger: 0.05 }, '<+.05')
    .to(specs, { yPercent: -100, stagger: 0.05 }, '<+.05')
    .to(arrow, { y: 60, autoAlpha: 0, stagger: -0.05 }, '<+.05')
    .to(footer, { y: window.innerHeight }, '<+.05')
}
