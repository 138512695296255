import { gsap } from 'gsap'
import { Component, Vue } from 'vue-property-decorator'

@Component
export class LocatorTransitions extends Vue {
  enter(el: HTMLElement, done: () => void) {
    gsap.from(el, { yPercent: 100, duration: 1.2, ease: 'expo.inOut', onComplete: done })
  }

  leave(el: HTMLElement, done: () => void) {
    gsap.to(el, { yPercent: 100, duration: 1.2, ease: 'expo.inOut', onComplete: done })
  }
}
